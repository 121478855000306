import { IPatientInfo } from './patient-info';

export interface Payment {
  id: string;
  pci_id: string;
  patient_account_id: string;
  patient_info_id: string;
  patient_info?: IPatientInfo;
  payment_dates?: {
    payment_completed_date?: number;
    payment_due_date: number;
    payment_cancelled_date?: number;
    payment_processed_date?: number;
    payment_failed_date?: number;
  };
  settlement_method: 'credit_card' | 'others';
  settlement_amount: SettlementAmount;
  memo: string;
  status?: 'created' | 'started' | 'completed' | 'failed' | 'cancelled';
  created_at?: number;
  payment_completed_date: number;
}

export interface SettlementAmount {
  insurance_covered_price: number;
  insurance_uncovered_price: number;
  other_price: number;
  shipping_fee: number;
}

export enum PaymentStatus {
  created = 'created',
  started = 'started',
  completed = 'completed',
  failed = 'failed',
  cancelled = 'cancelled',
}

export enum PaymentMethod {
  credit_card = 'credit_card',
  others = 'others',
}
